/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import {
  useReadErc20Allowance,
  useReadExchangeGetExchangeRate,
  useWriteErc20Approve,
  useWriteExchangeBuyPackage,
  useWriteExchangeUpgradePackage,
  useSimulateErc20Approve,
  useSimulateExchangeBuyPackage,
  useSimulateExchangeUpgradePackage,
} from '@app/contracts';
import { useAccount, useWaitForTransactionReceipt } from 'wagmi';
import { type Address, formatEther } from 'viem';
import { Package } from '@app/hooks/usePackages';
import { User } from '@app/hooks/useUser';
import { useProfile } from '@app/hooks';
import { CustomDialog } from '@app/components/shared/Dialog';
import CurrenciesSelect, { ICurrency } from '@app/components/CurrenciesSelect/CurrenciesSelect';
import { Grid, Link, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styles from './style.module.scss';
import process from 'process';
import { SPECIAL_RATE } from '@app/types';
import { useTranslation } from 'next-i18next';
import { useBalanceAndGasChecker } from '@app/hooks/useBalanceAndGasChecker';

const nf = new Intl.NumberFormat();

export const BuyPackage: React.FC<{
  user: User;
  package: Package;
  onClose: () => void;
}> = ({ user, package: uscaPackage, onClose }) => {
  const { data: profile } = useProfile();
  const { address, chain } = useAccount();
  const { t } = useTranslation('packages');
  const inviterAddress = profile?.inviterAddress;
  const [currencyObject, setCurrencyObject] = useState<ICurrency | undefined>();
  const [successTitle] = useState(t('buy_package.success_title'));

  const { data: allowance, refetch: refetchAllowance } = useReadErc20Allowance({
    address: currencyObject?.address! as Address,
    args: [address! as Address, process.env.EXCHANGE_ADDRESS! as Address],
  });

  const { data: rate } = useReadExchangeGetExchangeRate({
    address: process.env.EXCHANGE_ADDRESS as Address,
    args: [currencyObject?.address!],
  });

  const usdtPrice = uscaPackage.price(user.package)['usdt'];

  const selectedCurrencyPrice = rate ? (usdtPrice * SPECIAL_RATE) / rate : undefined;

  const enoughAllowance = BigInt(allowance ?? 0) >= BigInt(selectedCurrencyPrice ?? 0);

  const approveArgs =
    uscaPackage && !enoughAllowance
      ? ([process.env.EXCHANGE_ADDRESS as Address, selectedCurrencyPrice!] as const)
      : undefined;

  const buyArgs =
    uscaPackage && enoughAllowance && !user.package
      ? ([currencyObject?.address! as Address, uscaPackage.id, inviterAddress as Address] as const)
      : undefined;

  const upgradeArgs =
    uscaPackage && enoughAllowance && user.package
      ? ([currencyObject?.address! as Address, uscaPackage.id, inviterAddress as Address] as const)
      : undefined;

  const { data: approveConfig } = useSimulateErc20Approve({
    address: currencyObject?.address! as Address,
    args: approveArgs,
    query: {
      enabled: !!approveArgs,
    },
  });

  const {
    isPending: approveLoading,
    isSuccess: approveSuccess,
    writeContract: approve,
    data: approveData,
  } = useWriteErc20Approve();

  const { data: approveReceipt } = useWaitForTransactionReceipt({
    hash: approveData,
    query: {
      enabled: !enoughAllowance,
    },
  });

  const { data: buyConfig, error: prepareError } = useSimulateExchangeBuyPackage({
    address: process.env.EXCHANGE_ADDRESS as Address,
    args: buyArgs,
    query: {
      enabled: !!buyArgs,
    },
  });

  const { data: upgradeConfig } = useSimulateExchangeUpgradePackage({
    address: process.env.EXCHANGE_ADDRESS as Address,
    args: upgradeArgs,
    query: {
      enabled: !!upgradeArgs,
    },
  });

  const {
    data: hash,
    isPending: buyLoading,
    isSuccess: buySuccess,
    writeContractAsync: buy,
    error,
  } = user.package ? useWriteExchangeUpgradePackage() : useWriteExchangeBuyPackage();

  const { validateFunds: handleApproveValidateFunds } = useBalanceAndGasChecker({
    currencyAddress: currencyObject?.address ?? '',
    requiredAmount: +formatEther(selectedCurrencyPrice ?? BigInt(0)),
    onSuccess: () => {
      approve?.(approveConfig!.request);
    },
  });

  const { validateFunds: buyPackageValidateFunds } = useBalanceAndGasChecker({
    currencyAddress: currencyObject?.address ?? '',
    requiredAmount: +formatEther(selectedCurrencyPrice ?? BigInt(0)),
    onSuccess: () => {
      buy?.(user.package ? upgradeConfig!.request : buyConfig!.request);
    },
  });

  useEffect(() => {
    if (approveReceipt && approveReceipt.status === 'success') {
      refetchAllowance();
    }
  }, [approveReceipt]);

  if (error && window.location.href.includes('debug=true')) {
    window.document.write('error');
    window.document.write(error.message);
    window.document.write(JSON.stringify(error, null, 2));
  }

  if (prepareError && window.location.href.includes('debug=true')) {
    window.document.write('prepareError');
    window.document.write(prepareError.message);
    window.document.write(JSON.stringify(prepareError, null, 2));
  }

  const price = `${nf.format(+formatEther(selectedCurrencyPrice ?? BigInt(0)))}`;
  const size = nf.format(+formatEther(uscaPackage.size));
  const title =
    BigInt(user?.packageId ?? 0) > BigInt(0)
      ? `${t('buy_package.upgrade_to_package')} ${size}`
      : `${t('buy_package.buy_package')} ${size}`;

  const getAdditionalTokensMessage = (packageItem: Package): string => {
    if (!packageItem.unlimitedBuying && packageItem.tokensAvailable === BigInt(0)) {
      return t('package_card.no_additional');
    }

    if (packageItem.unlimitedBuying) {
      return t('package_card.possibility_unlimited');
    }
    return `${t('package_card.possibility_purchasing')} ${nf.format(
      +formatEther(packageItem.tokensAvailable),
    )} ${t('package_card.usca_tokens')}`;
  };

  return (
    <CustomDialog
      open
      title={buySuccess && hash && chain?.blockExplorers ? successTitle : title}
      handleClose={onClose}
    >
      {!buySuccess && (
        <>
          <Typography variant="body1">
            {t('buy_package.you_will_receive')}{' '}
            {nf.format(
              +formatEther(
                ((BigInt(uscaPackage.size) - (user.package?.size ?? BigInt(0))) * SPECIAL_RATE) /
                  SPECIAL_RATE,
              ),
            )}{' '}
            {t('buy_package.usca_tokens')}.
          </Typography>

          <Typography my={2} variant="body2">
            {getAdditionalTokensMessage(uscaPackage)}
          </Typography>
        </>
      )}

      {buySuccess && hash && chain?.blockExplorers && (
        <>
          <Typography variant="body1">{t('buy_package.dear_client')},</Typography>
          <Typography mb={4} variant="body1">
            {t('buy_package.your_transaction_successfully')}.
          </Typography>
          <Typography component={'div'} my={2} variant="body1">
            <Link
              href={`${chain.blockExplorers.default.url}/tx/${hash}`}
              target="_blank"
              rel="noreferer"
            >
              {t('buy_package.view_transaction')} {chain.blockExplorers.default.name}
            </Link>
          </Typography>

          <Grid container direction="column" mt={3}>
            <Button onClick={onClose} variant="outlined">
              {t('buy_package.dismiss')}
            </Button>
          </Grid>
        </>
      )}

      {!buySuccess && <CurrenciesSelect handlCurrencyChange={(item) => setCurrencyObject(item)} />}

      {!buySuccess && (
        <Grid container direction="column" mt={3}>
          {!enoughAllowance && (
            <LoadingButton
              variant="contained"
              className={styles.btn}
              loadingPosition="start"
              onClick={handleApproveValidateFunds}
              loading={approveLoading || (approveSuccess && !approveReceipt)}
              disabled={!approve || approveLoading || (approveSuccess && !approveReceipt)}
            >
              {approveLoading || (approveSuccess && !approveReceipt)
                ? t('buy_package.waiting_confirmation')
                : `${t('buy_package.allow_wallet_to_spend')} ${price} ${currencyObject?.currency}`}
            </LoadingButton>
          )}
          {enoughAllowance && (
            <LoadingButton
              variant="contained"
              className={styles.btn}
              loadingPosition="start"
              loading={buyLoading}
              onClick={buyPackageValidateFunds}
              disabled={buyLoading}
            >
              {t('buy_package.pay')} {price} {currencyObject?.currency}
            </LoadingButton>
          )}
        </Grid>
      )}
    </CustomDialog>
  );
};
