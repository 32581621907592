import React from 'react';
import { CustomDialog } from '@app/components/shared/Dialog';
import { Grid, Typography } from '@mui/material';
import styles from './style.module.scss';
import { EButtonType } from '@app/types';
import { Button } from '@app/components/shared/Button';
import { useTranslation } from 'next-i18next';

export const CannotLoginDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('packages');
  const title = t('cannot_login_dialog.title');

  return (
    <CustomDialog open title={title} handleClose={onClose}>
      <Typography variant="body1" mb={1}>
        {t('cannot_login_dialog.dear_client')},
      </Typography>
      <Typography variant="body1" mb={1}>
        {' '}
        {t('cannot_login_dialog.description')}
      </Typography>
      <Typography variant="body1">{t('cannot_login_dialog.make_sure')}</Typography>

      <Typography component={'div'} variant={'body2'} ml={2} mb={4}>
        <ul>
          {/*<li>You have Metamask or Trustwallet installed on your device.</li>*/}
          <li>{t('cannot_login_dialog.list_1')}</li>
          {/*<li>You have to complete KYC procedure for getting full access to all functionality.</li>*/}
          <li>{t('cannot_login_dialog.list_2')}</li>
          <li>{t('cannot_login_dialog.list_3')}</li>
          <li>{t('cannot_login_dialog.list_4')}</li>
        </ul>
      </Typography>

      <Grid container direction="column" mt={3}>
        <Button customClass={styles.btn} type={EButtonType.OUTLINED} onClick={onClose}>
          {t('cannot_login_dialog.dismiss')}
        </Button>
      </Grid>
    </CustomDialog>
  );
};
