import React, { useCallback, useState } from 'react';
import { Package, useModal, useUser, usePackages, useProfile } from '@app/hooks';
import { useAccount } from 'wagmi';
import { BuyPackage } from '../BuyPackage';
import { PackageCard } from '../PackageCard';
import { useTriggerRefresh } from '@app/hooks/useTriggerRefresh';
import { ECurrentModal } from '@app/types';
import { CannotLoginDialog } from '@app/components/CannotLoginDialog';
import { Preloader } from '@app/components/shared/Preloader';

export const PackagesList: React.FC<{ limit?: number }> = ({ limit }) => {
  const { packages, isLoading: isPackagesLoading } = usePackages();
  const { user, isLoading: isUserLoading, refetch: refetchUser } = useUser();
  const { isConnected } = useAccount();
  const { data: profile, isLoggedIn } = useProfile();
  const { setCurrentModal } = useModal();
  const [uscaPackage, setUscaPackage] = useState<Package | undefined>();
  const [noConnect, setNoConnect] = useState<boolean>(false);

  const buyOrUpgradeHandler = useCallback(
    (packageId: BigInt) => {
      if (isConnected && isLoggedIn && profile && !!profile.user) {
        setUscaPackage(packages?.find((p) => p.id === packageId));
      } else if (isConnected && !(isLoggedIn && profile && !!profile.user)) {
        setCurrentModal(ECurrentModal.FILL_PROFILE);
      } else {
        setNoConnect(true);
      }
    },
    [packages, isConnected, isLoggedIn, profile],
  );

  useTriggerRefresh(() => {
    refetchUser();
  });

  return (
    <>
      {(isPackagesLoading || isUserLoading) && <Preloader />}
      {packages
        ?.slice(0, limit ?? packages.length)
        .map((packageItem, idx) => (
          <PackageCard
            key={idx}
            packageItem={packageItem}
            activePackage={isLoggedIn ? user.package : undefined}
            buyCallback={buyOrUpgradeHandler}
          />
        ))}
      {uscaPackage && (
        <BuyPackage user={user} package={uscaPackage} onClose={() => setUscaPackage(undefined)} />
      )}
      {noConnect && <CannotLoginDialog onClose={() => setNoConnect(false)} />}
    </>
  );
};
