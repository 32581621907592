import { useCallback } from 'react';
import { Package } from '@app/hooks/usePackages';
import { formatEther } from 'viem';
import BlackVerifyFlagIcon from 'src/assets/icons/BlackVerifyFlagIcon.svg';
import { Button } from '../../shared/Button';
import { EButtonType } from '@app/types';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './style.module.scss';
import { Typography } from '@mui/material';
import { packageNameConverter } from '@app/utils/packages';
import { useTranslation } from 'next-i18next';

type Props = {
  packageItem: Package;
  activePackage: Package | undefined;
  buyCallback: (packageId: BigInt) => void;
};

// const pf = new Intl.NumberFormat(undefined, { style: 'percent' });
const nf = new Intl.NumberFormat();

export const PackageCard = ({ packageItem, activePackage, buyCallback }: Props) => {
  const { t } = useTranslation('packages');
  const userHasPackage = activePackage?.id > 0;
  const isActivePackage = activePackage?.id === packageItem.id;
  const isSmallerPackage = activePackage?.size > packageItem.size;
  const getBtnTxt = () => {
    return userHasPackage
      ? isActivePackage
        ? t('package_card.current')
        : t('package_card.upgrade')
      : t('package_card.buy');
  };

  const handleBuyBtn = useCallback(
    () => buyCallback(packageItem.id),
    [buyCallback, packageItem.id],
  );
  const dollarPrice = nf.format(+formatEther(packageItem.price(activePackage).usdt));
  const size = nf.format(+formatEther(packageItem.size));
  const sizeName = packageNameConverter(+formatEther(packageItem.size));
  const sizeDiff = nf.format(+formatEther(packageItem.value(activePackage)));

  const getAdditionalTokensMessage = (packageItem: Package): string => {
    if (!packageItem.unlimitedBuying && packageItem.tokensAvailable === BigInt(0)) {
      return t('package_card.no_additional');
    }

    if (packageItem.unlimitedBuying) {
      return t('package_card.possibility_unlimited');
    }
    return `${t('package_card.possibility_purchasing')} ${nf.format(
      +formatEther(packageItem.tokensAvailable),
    )} ${t('package_card.usca_tokens')}`;
  };

  return (
    <div
      className={classNames({
        [styles['card']]: true,
        [styles['cardSample']]: !isActivePackage,
        [styles['cardSelected']]: isActivePackage,
      })}
    >
      <div className={styles.cardBody}>
        <div className={styles.header}>
          {isActivePackage && (
            <div className={styles.image}>
              <Image src={BlackVerifyFlagIcon} alt="verify" width={18} height={22} />
            </div>
          )}
        </div>
        <div className={styles.titleWrapper}>
          <h2 style={{ marginBottom: '10px' }}>
            <span>{sizeName}</span>
          </h2>
          <h2 className={styles.title}>
            {t('package_card.package')} {`${size}`}
          </h2>

          {!isActivePackage && !isSmallerPackage && (
            <Typography variant="subtitle2" mt={1}>
              {t('package_card.tokens_to_be_credited')}: {sizeDiff}
            </Typography>
          )}
        </div>

        <div className={styles.purchase}>
          <p>{getAdditionalTokensMessage(packageItem)}</p>
        </div>

        {!isActivePackage && !isSmallerPackage && (
          <div className={styles.price}>
            <h3>
              {dollarPrice}&nbsp;<span>USDT</span>
            </h3>
          </div>
        )}
      </div>

      <div className={styles.buttonWrapper}>
        {isActivePackage && (
          <Button type={EButtonType.FILLED} customClass={styles.buttonCurrent} disabled={true}>
            {t('package_card.current')}
          </Button>
        )}
        {!isSmallerPackage && !isActivePackage && (
          <Button type={EButtonType.FILLED} customClass={styles.button} onClick={handleBuyBtn}>
            {getBtnTxt()}
          </Button>
        )}
      </div>
    </div>
  );
};
